<template>
  <div class="home">
    <el-tabs v-model="activeName"
             @tab-click="handleClick">
      <el-tab-pane label="上账明细"
                   name="first"></el-tab-pane>
      <el-tab-pane label="操作记录"
                   name="second"></el-tab-pane>
    </el-tabs>
    <Condition :modalFormData="modalFormData" :functionCode="functionCode" @adjusTable="adjusTable"/>
  </div>
</template>

<script>
import Condition from './condition.vue';

export default {
  name: 'DiscountDetail',
  props: {
    modalFormData: Object,
  },
  components: {
    Condition,
  },
  data() {
    return {
      activeName: 'first',
      functionCode: 'dms_discount_condition',
    };
  },
  created() {
    console.log(this.modalFormData);
  },
  methods: {
    adjusTable() {
      this.$emit('onGetList');
    },
    handleClick(tab) {
      this.tabType = tab.name;
      switch (tab.name) {
        case 'first': this.functionCode = 'dms_discount_condition'; break;
        case 'second': this.functionCode = 'dms_discount_condition_detail'; break;

        default:
          break;
      }
    },
  },
};
</script>

<style lang="less" scoped>
</style>
